/* styles.css */
.ToastRoot[data-swipe='move'] {
  transform: translateY(var(--radix-toast-swipe-move-y));
}
.ToastRoot[data-swipe='cancel'] {
  transform: translateY(0);
  transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
  animation: slideRight 100ms ease-out;
}

@keyframes slideRight {
  from {
    transform: translateY(var(--radix-toast-swipe-end-y));
  }
  to {
    transform: translateY(100%);
  }
}
