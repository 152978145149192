@import '@unocss/reset/tailwind.css';
@unocss all;
@font-face {
  font-family: 'D-DINExp';
  src: url('/fonts/D-DINExp.otf') format('opentype');
}

:root {
  --body-bg-color: #fff;
  --primary-color: #c43737;
}

@media (prefers-color-scheme: dark) {
  :root {
  }
}

html,
body {
  min-height: 100%;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  width: 100vw;
  overflow: hidden;
  /* padding-left: calc(100vw - 100%); */
  background: var(--body-bg-color);
  font-family:
    'D-DINExp',
    -apple-system,
    BlinkMacSystemFont,
    'PingFang SC',
    'Helvetica Neue',
    STHeiti,
    'Microsoft Yahei',
    Tahoma,
    Simsun,
    sans-serif;
  /* padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom); */
}

input {
  outline: none;
}

input::placeholder {
  color: [rgba(0, 0, 0, 0.7)];
}

textarea {
  outline: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
